<template>
  <div>
    <el-row>
      <el-col :span="11" :offset="4">
        <Table :dataList="dataList" />
      </el-col>
      <el-col :span="4" :offset="1">
        <Recomm />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Table from "@/components/dataDownload/table"
import Recomm from "@/components/dataDownload/recomm"
export default {
  components:{
    Table,Recomm
  },
  data(){
    return {
      dataList:[],  // 用来存放资料列表
    }
  },
  created(){
    this.getDataList()
  },
  methods:{
    getDataList(){
      this.Api.Data.getDataList().then(res => {
        this.dataList = res.data
      })
    }
  }
}
</script>

<style scoped>

</style>