<template>
  <div>
    <el-card>
      <el-table :data="dataList" style="width: 100%">
        <el-table-column label="序号" prop="did" width="80px"></el-table-column>
        <el-table-column label="文件名称" prop="dtitle"></el-table-column>
        <el-table-column
          label="下载次数"
          prop="dcount"
          width="100px"
        ></el-table-column>
        <el-table-column label="下载" prop="did" width="50px">
          <el-button type="text" @click="down">下载</el-button>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    down() {
      alert("暂未开发");
    },
  },
};
</script>

<style scoped></style>
