<template>
  <div>
    <p class="recommTitle">相关课程推荐</p>
    <Item :ExcellentClassList="ExcellentClassList" type="recomm" />
  </div>
</template>

<script>
import Item from "@/components/public/ExcellentClass/item";

export default {
  components: {
    Item,
  },
  data() {
    return {
      ExcellentClassList: [],
    };
  },
  created(){
    this.getRecCourse()  // 获取课程推荐数据
  },
  methods:{
    getRecCourse(){
      this.Api.Curr.getRecommendCourse().then(res => {
        this.ExcellentClassList.push(res.data)
      })
    }
  }
};
</script>

<style scoped>
.recommTitle {
  border-left: 3px solid #f08041;
  text-align: left !important;
  text-indent: 10px;
}
</style>
